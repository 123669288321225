export const RETURNS_MANAGEMENT = {
  title: 'Returns',
  LISTING: {
    title: 'View / Manage Returns',
  },
  CREATE_RETURN: {
    title: 'Create Return',
  },
  RETURN_ORDER_DETAIL: {
    title: 'Return Order',
  },
};
