import React from 'react';
import async from '../Components/Async';
import { getUserDashboardFlags, applyDashboardFlags } from './helpers';
import { UpsellText } from './others';
import { paths } from './constants';
import {
  BILLING,
  CARRIER,
  HOME,
  INBOUND,
  LOCAD_STORAGE_AND_FULFILLMENT,
  ORDER,
  PICKUP_LOCATIONS,
  PRODUCTS,
  PULLOUT_AND_B2B,
  RETURNS,
  SALES_OVERVIEW,
  SETTINGS,
  SHIPMENTS,
  SHIPPING,
  SUPPORT,
  WALLET,
  OTHERS,
  FULFILLMENT,
  AUTH,
  RETURNS_MANAGEMENT,
} from '../constants';
import {
  Home,
  Storage,
  Settings,
  Launch,
  LocalShipping,
  AccountBalanceWallet,
  Help,
  Room,
  Share,
  SubdirectoryArrowLeft,
  Redeem as GiftIcon,
  ShoppingCart,
} from '@mui/icons-material/';
import SalesOverviewSVG from '../layouts/svgIcons/SalesOverview';
import ReportSVG from '../layouts/svgIcons/Report';
import InboundSVG from '../layouts/svgIcons/Inbound';
import PulloutB2BSVG from '../layouts/svgIcons/PulloutB2B';
import ProductSVG from '../layouts/svgIcons/Product';
import DollarSVG from '../layouts/svgIcons/Dollar';
import StorageAndFulfillment from '../layouts/svgIcons/StorageAndFulfillment';
import { PERMISSIONS } from '../Auth/Constants/constants';
import { GWP } from '../constants/gwp';
// import { RETURNS_MANAGEMENT } from '../ReturnOrders/constants';

//Payment
const LocadPayment = async(() => import('../Payment/Views/PaymentMethod'));
const LocadPaymentCallback = async(() => import('../Payment/Views/PaymentCallback'));

// Auth components
// const MigrationUserConfirmation = async(() => import('../Auth/Views/MigrationConfirmation')); // this should be removed once migration is done
// const MigrateUserComponent = async(() => import('../Auth/Views/UserMigration'));
// const MigrationUserCallBack = async(() => import('../Auth/Views/MigrateUserCallBack'));
const Login = async(() => import('../Auth/Views/Login'));
const Warning = async(() => import('../Auth/Views/Warning'));
const ConfirmScreen = async(() => import('../Auth/Views/ConfirmScreen'));
const SignUp = async(() => import('../Auth/Views/Signup'));
const Page404 = async(() => import('../Auth/Views/Page404'));
const Page500 = async(() => import('../Auth/Views/Page500'));
const ExpiredInviteWarning = async(() => import('../Auth/Views/ExpiredInviteWarning'));
const SignUPEmailInvite = async(() => import('../Auth/Views/EmailInviteSignUP'));
const AuthCallback = async(() => import('../Auth/Views/AuthCallback'));
const FreshdeskLogin = async(() => import('../Auth/Views/FreshdeskLogin'));
const EmailInvitationCallBack = async(() => import('../Auth/Views/EmailInviteCallBack'));
const LogoutScreen = async(() => import('../Auth/Views/Logout'));
// const SignUpBrandCreate = async(() => import("../Auth/Views/Onboarding/BrandCreation"))
// const SignUpBrandMapping = async(() => import("../Auth/Views/Onboarding/LocationMapping"));

//Shopify Sign up
const ShopifyIntegration = async(() => import('../Auth/Views/Shopify'));
const ShopifyCreateUser = async(() => import('../Auth/Components/CreateUser'));
const ShopifyUserMapLocation = async(() => import('../Auth/Components/MapLocation'));
const ShopifyHubspotUserSignin = async(() => import('../Auth/Components/HubspotUser'));

//Amazon Sign up
const AmazonIntegration = async(() => import('../Auth/Views/Amazon'));

// Pages components
const SalesOverview = async(() => import('../pages/dashboards/SalesOverview'));
const HomepageDashboard = async(() => import('../pages/dashboards/HomepageDashboard/Views/'));
const ReturnDashboard = async(() => import('../pages/dashboards/ReturnDashboard'));
const Orders = async(() => import('../Order/Views/Order'));
const GenericBulkUpload = async(() => import('../Components/GenericBulkUpload'));
const FulfillmentDashboard = async(() => import('../Fulfillment/Views/ZeusFulfillmentDashboard'));
const InventoryDashboard = async(() => import('../Inventory/Views/Dashboard/InventoryDashboard'));
const OrderForcasting = async(() => import('../Order/Views/Forecast/OrderForecast'));
const Products = async(() => import('../Inventory/Views/InventoryProducts/Products'));

const AccountSettings = async(() => import('../Settings/Account/Views'));
const MarketplaceSettingsV2 = async(() => import('../pages/MarketplaceSettings/index.v2'));
const WarehouseSettings = async(() => import('../Settings/WarehouseSettings'));
const CarrierSettings = async(() => import('../Carrier/Views'));
const CarrierManagement = async(() => import('../Settings/CarrierManagement/Views'));
const MarketplaceDetailsV2 = async(() => import('../pages/MarketplaceSettings/marketplace/details/index.v2'));

const GenerateReport = async(() => import('../Reports/Views/GenerateReport'));
const GeneratedReport = async(() => import('../Reports/Views/GeneratedReports'));
const GeneratedScheduledReport = async(() => import('../Reports/Views/GeneratedScheduledReports'));

const WarehouseDetails = async(() => import('../Settings/WarehouseSettings/Detail'));
const CarrierDetails = async(() => import('../Carrier/Views/Carriersettings/Detail'));
const OrderListing = async(() => import('../Order/Views/List/Orders'));
const CatalogueListing = async(() => import('../Inventory/Views/CatalogueProducts'));
const InventoryListing = async(() => import('../Inventory/Views/InventoryProducts/InventoryListing'));
const InventoryProductSKUs = async(() => import('../Inventory/Views/SKUUploads'));
const GiftWithPurchaseCreate = async(() => import('../GiftWithPurchase/Views/CreatePromotion'));
const GiftWithPurchaseListing = async(() => import('../GiftWithPurchase/Views/ManagePromotions'));
const GiftWithPurchaseDetail = async(() => import('../GiftWithPurchase/Views/Detail'));
const ShopeeAuthorize = async(() => import('../pages/MarketplaceSettings/marketplace/segment/shopee/authorize'));
const LazadaAuthorize = async(() => import('../pages/MarketplaceSettings/marketplace/segment/lazada/authorize'));
const TiktokAuthorize = async(() => import('../pages/MarketplaceSettings/marketplace/segment/tiktok/authorize'));
const WixAuthorize = async(() => import('../pages/MarketplaceSettings/marketplace/segment/wix/authorize'));
const TokopediaAuthorize = async(() => import('../pages/MarketplaceSettings/marketplace/segment/tokopedia/authorize'));
const ZaloraAuthorize = async(() => import('../pages/MarketplaceSettings/marketplace/segment/zalora/authorize'));
const CapillaryAuthorize = async(() => import('../pages/MarketplaceSettings/marketplace/segment/capillary/authorize'));
const CustomWebstoreAuthorize = async(() =>
  import('../pages/MarketplaceSettings/marketplace/segment/custom/authorize')
);
const KumuAuthorize = async(() => import('../pages/MarketplaceSettings/marketplace/segment/kumu/authorize'));
const BboAuthorize = async(() => import('../pages/MarketplaceSettings/marketplace/segment/bbo/authorize'));
const GineeAuthorize = async(() => import('../pages/MarketplaceSettings/marketplace/segment/ginee/authorize'));
const ShopifyAuthorize = async(() => import('../pages/MarketplaceSettings/marketplace/segment/shopify/authorize'));
const WoocommerceAuthorize = async(() =>
  import('../pages/MarketplaceSettings/marketplace/segment/woocommerce/authorize')
);
const EbayAuthorize = async(() => import('../pages/MarketplaceSettings/marketplace/segment/ebay/authorize'));
const AmazonAuthorize = async(() => import('../pages/MarketplaceSettings/marketplace/segment/amazon/authorize'));

// const ShopifyValidatePrivate = async(() =>
//   import("../pages/MarketplaceSettings/marketplace/segment/shopify/validate/private")
// );
// const ShopifyValidateCustom = async(() =>
//   import("../pages/MarketplaceSettings/marketplace/segment/shopify/validate/custom")
// );
// const ShopifyPostSetup = async(() => import("../pages/MarketplaceSettings/marketplace/segment/shopify/postsetup"));
const WareoAuthorize = async(() => import('../Settings/WarehouseSettings/segment/wareo/authorize'));
const Wareo3Authorize = async(() => import('../Settings/WarehouseSettings/segment/wareo3/authorize'));
const UniwareAuthorize = async(() => import('../Settings/WarehouseSettings/segment/uniware/authorize'));
const LogiwaAuthorize = async(() => import('../Settings/WarehouseSettings/segment/logiwa/authorize'));
const NinjavanAuthorize = async(() => import('../Carrier/Views/Carriersettings/Segment/ninjavan/authorize'));
const EntregoAuthorize = async(() => import('../Carrier/Views/Carriersettings/Segment/entrego/authorize'));
const XDEAuthorize = async(() => import('../Carrier/Views/Carriersettings/Segment/xde/authorize'));
const LalamoveAuthorize = async(() => import('../Carrier/Views/Carriersettings/Segment/lalamove/authorize'));
const PickuppAuthorize = async(() => import('../Carrier/Views/Carriersettings/Segment/pickupp/authorize'));
const JntPhAuthorize = async(() => import('../Carrier/Views/Carriersettings/Segment/jntph/authorize'));
const LbcAuthorize = async(() => import('../Carrier/Views/Carriersettings/Segment/lbc/authorize'));
const DhlAuthorize = async(() => import('../Carrier/Views/Carriersettings/Segment/dhl/authorize'));
const AusPostAuthorize = async(() => import('../Carrier/Views/Carriersettings/Segment/auspost/authorize'));
const AsendiaAuthorize = async(() => import('../Carrier/Views/Carriersettings/Segment/asendia/authorize'));
const SingpostAuthorize = async(() => import('../Carrier/Views/Carriersettings/Segment/singpost/authorize'));
const SendleAuthorize = async(() => import('../Carrier/Views/Carriersettings/Segment/sendle/authorize'));
const KerryAuthorize = async(() => import('../Carrier/Views/Carriersettings/Segment/kerry/authorize'));
const TeleportAuthorize = async(() => import('../Carrier/Views/Carriersettings/Segment/teleport/authorize'));
const ZeusSalesOverview = async(() => import('../pages/dashboards/ZeusSalesOverview'));
const ZeusFulfillmentDashboard = async(() => import('../Fulfillment/Views/ZeusFulfillmentDashboard'));
const ZeusFulfillmentDashboardV2 = async(() => import('../Fulfillment/Views/ZeusFulfillmentDashboardV2'));
const ZeusInventoryDashboard = async(() => import('../Inventory/Views/Dashboard/ZeusInventoryDashboard'));
const Privacy = async(() => import('../pages/footer/Privacy'));
const TermsOfService = async(() => import('../pages/footer/TermsOfService'));
const CreateInventoryProduct = async(() => import('../pages/CreateInventoryProduct'));
const CarrierOrderListingContainer = async(() => import('../pages/listings/Shipments'));
// const ShipmentOrders = async(() => import('../pages/listings/ShipmentOrders'));
const VersionThreeSalesOverview = async(() => import('../pages/dashboards/VersionThreeSalesOverview'));
// const CreateShipmentOrder = async(() => import('../pages/CreateShipmentOrder'));
//const CreatePickupLocation = async(() => import('../pages/CreatePickupLocation'));
const CreatePickupLocation = async(() => import('../Shipment/Views/CreatePickupLocation'));
const AdvanceShippingNotes = async(() => import('../Inbound/Views/AdvanceShippingNotes'));
const ReturnOrdersList = async(() => import('../ReturnOrders/Views/List'));
const CreateReturn = async(() => import('../ReturnOrders/Views/Create'));
const ReturnOrderDetail = async(() => import('../ReturnOrders/Views/Detail'));
const AdvanceShippingNotesDetail = async(() => import('../Inbound/Views/AdvanceShippingNotes/Details'));
const ASNUpload = async(() => import('../pages/consignments/ASNUpload'));
const Pullouts = async(() => import('../B2B/Views/PulloutTracking'));
const B2BReview = async(() => import('../B2B/Views/ReviewDetails'));
const CreateDistributionOrder = async(() => import('../B2B/Views/CreateDistributionOrder'));
const PulloutsDetail = async(() => import('../B2B/Views/PulloutsDetails'));
//const PulloutsUpload = async(() => import('../pages/consignments/PulloutUpload'));
const FulfillmentBilling = async(() => import('../Billing/Views/Fulfillmentbilling'));
const ReturnBilling = async(() => import('../Billing/Views/ReturnBilling'));
const B2BBilling = async(() => import('../Billing/Views/B2BBilling'));
const PackagingBilling = async(() => import('../Billing/Views/PackagingBilling'));
const BillingDashboard = async(() => import('../Billing/Views/BillingDashboard'));
const StorageBilling = async(() => import('../Billing/Views/StorageBilling'));
const VasBilling = async(() => import('../Billing/Views/VasBilling'));
const RatesBilling = async(() => import('../Billing/Views/Rates'));
//const PickupLocations = async(() => import('../pages/listings/PickupLocations'));
const PickupLocations = async(() => import('../Shipment/Views/PickupLocations'));
const JntSgAuthorize = async(() => import('../Carrier/Views/Carriersettings/Segment/jntsg/authorize'));
const ShipmentDashboard = async(() => import('../Shipment/Views/ShipmentDashboard'));
const ShipmentOrders = async(() => import('../Shipment/Views/ShipmentOrders'));
const CreateShipmentOrder = async(() => import('../Shipment/Views/CreateShipmentOrder'));
const PendingPickups = async(() => import('../Shipment/Views/PendingPickups'));
const TrackShipments = async(() => import('../Shipment/Views/TrackShipments'));
const EditStorageRateView = async(() => import('../Billing/Views/Rates/EditStorageRateView'));
const EditFulfillmentRateView = async(() => import('../Billing/Views/Rates/EditFulfillmentRateView'));
const EditReturnRateView = async(() => import('../Billing/Views/Rates/EditReturnRateView'));
const EditPulloutRateView = async(() => import('../Billing/Views/Rates/EditPulloutRateView'));
const ShipmentBilling = async(() => import('../Billing/Views/ShipmentBilling'));

const WalletSummaray = async(() => import('../Wallet/Views'));
const StorageAndFulfillmentUpselling = async(() => import('../Fulfillment/Views/StorageAndFulfillmentUpselling'));
const Support = async(() => import('../Components/Support'));

const SALES_OVERVIEW_COMPONENT_TO_DASHBOARD_VERSION_MAPPING = {
  1: SalesOverview,
  2: ZeusSalesOverview,
  3: VersionThreeSalesOverview,
  4: ZeusSalesOverview,
};
const FULFILLMENT_DASHBOARD_COMPONENT_TO_DASHBOARD_VERSION_MAPPING = {
  1: FulfillmentDashboard,
  2: ZeusFulfillmentDashboard,
  4: ZeusFulfillmentDashboard,
};
const ORDERS_COMPONENT_TO_DASHBOARD_VERSION_MAPPING = {
  1: Orders,
  2: OrderListing,
  4: OrderListing,
};

const getSalesRoutes = (version) => ({
  id: SALES_OVERVIEW.title,
  path: paths.SALES_OVERVIEW,
  name: SALES_OVERVIEW.title,
  icon: <SalesOverviewSVG viewBox="0 0 36 36" />,
  component: SALES_OVERVIEW_COMPONENT_TO_DASHBOARD_VERSION_MAPPING[version],
});

const getWalletRoutes = (version) => ({
  id: WALLET.title,
  path: paths.Wallet,
  name: WALLET.title,
  icon: <AccountBalanceWallet />,
  component: WalletSummaray,
});

const getFulfillmentRoutes = (version, permissions = []) => {
  const canViewOrderUploads = permissions.includes(PERMISSIONS.VIEW_ORDER_UPLOADS);

  const dashboardRoute = {
    path: paths.FULFILLMENT_DASHBOARD,
    name: ORDER.ORDER_DASHBOARD.title,
    component: FULFILLMENT_DASHBOARD_COMPONENT_TO_DASHBOARD_VERSION_MAPPING[version],
  };

  const ordersUploadsRoute = {
    path: paths.FULFILLMENT_ORDERS_UPLOADS,
    name: ORDER.ORDER_UPLOADS.title,
    component: (props) => (
      <GenericBulkUpload
        bulkUploadType={ORDER.ORDER_UPLOADS.uploadType}
        title={ORDER.ORDER_UPLOADS.title}
        view_permission={PERMISSIONS.VIEW_ORDER_UPLOADS}
        {...props}
      />
    ),
  };

  const ordersRoute = {
    path: paths.FULFILLMENT_ORDERS,
    name: ORDER.MANAGE_ORDERS.title,
    component: ORDERS_COMPONENT_TO_DASHBOARD_VERSION_MAPPING[version],
  };

  const orderForecastRoute = {
    id: ORDER.ORDER_FORECAST.title,
    path: paths.FULFILLMENT_ORDER_FORECAST,
    name: ORDER.ORDER_FORECAST.title,
    component: OrderForcasting,
  };

  const carrierOrdersRoute = {
    id: SHIPMENTS.title,
    path: paths.FULFILLMENT_CARRIER_ORDERS,
    name: SHIPMENTS.title,
    component: CarrierOrderListingContainer,
  };

  const shipmentOrdersRoute = {
    id: SHIPPING.title,
    path: paths.FULFILLMENT_SHIPPING,
    name: SHIPPING.title,
    component: ShipmentOrders,
  };

  const pickupLocationsRoute = {
    id: PICKUP_LOCATIONS.title,
    path: paths.FULFILLMENT_PICKUP_LOCATIONS,
    name: PICKUP_LOCATIONS.title,
    component: PickupLocations,
  };

  const FULFILLMENT_CHILDREN_TO_DASHBOARD_VERSION_MAPPING = {
    1: [dashboardRoute, ordersRoute, orderForecastRoute, canViewOrderUploads ? ordersUploadsRoute : null].filter(
      Boolean
    ),
    2: [
      dashboardRoute,
      ordersRoute,
      carrierOrdersRoute,
      orderForecastRoute,
      canViewOrderUploads ? ordersUploadsRoute : null,
    ].filter(Boolean),
    3: [shipmentOrdersRoute, pickupLocationsRoute],
    4: [dashboardRoute, ordersRoute, orderForecastRoute, canViewOrderUploads ? ordersUploadsRoute : null].filter(
      Boolean
    ),
  };

  return {
    id: ORDER.title,
    name: ORDER.MANAGE_ORDERS.title,
    path: paths.FULFILLMENT,
    icon: <ShoppingCart />,
    children: FULFILLMENT_CHILDREN_TO_DASHBOARD_VERSION_MAPPING[version],
    component: null,
  };
};

const getShipmentRoutes = () => {
  const dashboardRoute = {
    path: paths.SHIPMENTS_DASHBOARD,
    name: SHIPMENTS.SHIPMENT_D.title,
    component: ShipmentDashboard,
  };

  const manageShipmentRoute = {
    path: paths.MANAGE_SHIPMENTS,
    name: SHIPMENTS.BOOK_SHIPMENTS.title,
    component: ShipmentOrders,
  };

  const pendingPickupRoute = {
    path: paths.PENDING_PICKUPS,
    name: SHIPMENTS.PENDING_PICKUPS.title,
    component: PendingPickups,
  };

  const trackShipments = {
    path: paths.TRACK_SHIPMENTS,
    name: SHIPMENTS.TRACK_SHIPMENT.title,
    component: TrackShipments,
  };
  const Objroutes = {
    id: SHIPMENTS.title,
    name: SHIPMENTS.title,
    path: paths.SHIPMENTS,
    icon: <LocalShipping />,
    children: [dashboardRoute, manageShipmentRoute, pendingPickupRoute, trackShipments],
    component: null,
  };
  return Objroutes;
};

const getPickupLocationsRoutes = () => ({
  id: PICKUP_LOCATIONS.title,
  path: paths.PICKUP_LOCATIONS,
  name: PICKUP_LOCATIONS.title,
  icon: <Room />,
  component: PickupLocations,
});

const getBillingRoutes = (version, permissions, user) => {
  if (!user?.isBillingModuleVisible) {
    return {};
  }
  const hasShipmentBillingPermission = permissions.includes(PERMISSIONS.VIEW_SHIPMENT_BILLING);

  const Dashboard = {
    path: paths.BILLING_DASHBOARD,
    name: BILLING.BILLIG_D.title,
    component: BillingDashboard,
  };
  const FulfillmentBillingRoute = {
    path: paths.FULFILLMENT_BILLING,
    name: BILLING.FULFILLMENT_BILLING.title,
    component: FulfillmentBilling,
  };
  const ReturnBillingRoute = {
    path: paths.RETURN_BILLING,
    name: BILLING.RETURN_BILLING.title,
    component: ReturnBilling,
  };
  const B2BBillingRoute = {
    path: paths.B2B_BILLING,
    name: BILLING.B2B_BILLING.title,
    component: B2BBilling,
  };
  const PackagingBillingRoute = {
    path: paths.PACKAGING_BILLING,
    name: BILLING.PACKAGING_BILLING.title,
    component: PackagingBilling,
  };
  const StorageBillingRoute = {
    path: paths.STORAGE_BILLING,
    name: BILLING.STORAGE_BILLING.title,
    component: StorageBilling,
  };
  const VasBillingRoute = {
    path: paths.VAS_BILLING,
    name: BILLING.VAS_BILLING.title,
    component: VasBilling,
  };
  const RatesBillingRoute = {
    path: paths.RATES_BILLING,
    name: BILLING.BILLING_RATES.title,
    component: RatesBilling,
  };
  const shipmentBillingRoute = {
    path: paths.SHIPMENT_BILLING,
    name: BILLING.SHIPMENT_BILLING.title,
    component: ShipmentBilling,
  };

  const billingRoutes = [
    Dashboard,
    FulfillmentBillingRoute,
    B2BBillingRoute,
    PackagingBillingRoute,
    StorageBillingRoute,
    VasBillingRoute,
    RatesBillingRoute,
    ReturnBillingRoute,
  ];

  if (hasShipmentBillingPermission) {
    billingRoutes.push(shipmentBillingRoute);
  }

  const BILLING_ROUTES_TO_DASHBOARD_VERSION_MAPPING = {
    2: billingRoutes,
    3: billingRoutes,
    4: billingRoutes,
  };

  return {
    id: BILLING.title,
    name: BILLING.title,
    path: paths.BILLING,
    icon: <DollarSVG viewBox="0 0 34 34" />,
    children: BILLING_ROUTES_TO_DASHBOARD_VERSION_MAPPING[version],
    component: null,
  };
};

const getInventoryRoutes = (version) => {
  const INVENTORY_DASHBOARD_COMPONENT_TO_DASHBOARD_VERSION_MAPPING = {
    1: InventoryDashboard,
    2: ZeusInventoryDashboard,
    4: ZeusInventoryDashboard,
  };
  const INVENTORY_PRODUCT_COMPONENT_TO_DASHBOARD_VERSION_MAPPING = {
    1: Products,
    2: InventoryListing,
    4: InventoryListing,
  };
  const dashboardRoute = {
    path: paths.INVENTORY_DASHBOARD,
    name: PRODUCTS.PRODUCT_DASHBOARD.title,
    component: INVENTORY_DASHBOARD_COMPONENT_TO_DASHBOARD_VERSION_MAPPING[version],
  };

  const catalogueProductsRoute = {
    path: paths.INVENTORY_CATALOGUE_LISTING,
    name: PRODUCTS.CATALOGUE_PRODUCTS.title,
    component: CatalogueListing,
  };

  const inventoryProductsRoute = {
    path: paths.INVENTORY_PRODUCTS,
    name: PRODUCTS.INVENTORY_PRODUCTS.title,
    component: INVENTORY_PRODUCT_COMPONENT_TO_DASHBOARD_VERSION_MAPPING[version],
  };

  const inventoryProductsSKUsRoute = {
    path: paths.INVENTORY_PRODUCT_SKU_UPLOADS,
    name: PRODUCTS.PRODUCT_UPLOADS.title,
    component: InventoryProductSKUs,
  };

  const INVENTORY_DASHBOARD_CHILDREN_TO_DASHBOARD_VERSION_MAPPING = {
    1: [dashboardRoute, inventoryProductsRoute, inventoryProductsSKUsRoute],
    2: [dashboardRoute, catalogueProductsRoute, inventoryProductsRoute, inventoryProductsSKUsRoute],
    4: [dashboardRoute, catalogueProductsRoute, inventoryProductsRoute, inventoryProductsSKUsRoute],
  };

  return {
    id: PRODUCTS.title,
    name: PRODUCTS.title,
    path: paths.INVENTORY,
    icon: <ProductSVG viewBox="0 0 32 32" />,
    children: INVENTORY_DASHBOARD_CHILDREN_TO_DASHBOARD_VERSION_MAPPING[version],
    component: null,
  };
};

const getInboundRoutes = (version) => {
  const AdvanceShippingNotesRoute = {
    path: paths.ADVANCE_SHIPPING_NOTES,
    name: INBOUND.INBOUND_TRACKING.title,
    component: AdvanceShippingNotes,
  };

  const ASNUploadRoute = {
    path: paths.ADVANCE_SHIPPING_NOTES_UPLOAD,
    name: INBOUND.INBOUND_REQUESTS.title,
    component: ASNUpload,
  };

  const INBOUND_ROUTES_TO_DASHBOARD_VERSION_MAPPING = {
    2: [AdvanceShippingNotesRoute, ASNUploadRoute],
    3: [],
    4: [AdvanceShippingNotesRoute, ASNUploadRoute],
  };

  return {
    id: INBOUND.title,
    name: INBOUND.title,
    path: paths.INBOUND,
    icon: <InboundSVG viewBox="2 0 30 30" />,
    children: INBOUND_ROUTES_TO_DASHBOARD_VERSION_MAPPING[version],
    component: null,
  };
};

const returnKeyDashboardRoute = {
  id: RETURNS.title,
  path: paths.RETURNS_DASHBOARD,
  name: RETURNS.title,
  icon: <Launch />,
  component: ReturnDashboard,
};

const getReturnRoutes = (version, permissions) => {
  const canViewReturns = permissions.includes(PERMISSIONS.VIEW_RETURNS);
  const canCreateReturns = permissions.includes(PERMISSIONS.CREATE_RETURNS);

  const ReturnOrdersListRoute = {
    path: paths.RETURN_ORDERS_LISTING,
    name: RETURNS_MANAGEMENT.LISTING.title,
    component: ReturnOrdersList,
  };
  const CreateReturnRoute = {
    path: paths.CREATE_RETURN,
    name: RETURNS_MANAGEMENT.CREATE_RETURN.title,
    component: CreateReturn,
  };

  const RETURN_ROUTES_TO_DASHBOARD_VERSION_MAPPING = {
    2: [ReturnOrdersListRoute],
    3: [],
    4: [ReturnOrdersListRoute],
  };

  if (canCreateReturns) {
    RETURN_ROUTES_TO_DASHBOARD_VERSION_MAPPING[4].push(CreateReturnRoute);
    RETURN_ROUTES_TO_DASHBOARD_VERSION_MAPPING[2].push(CreateReturnRoute);
  }

  if (canViewReturns) {
    return {
      id: RETURNS_MANAGEMENT.title,
      name: RETURNS_MANAGEMENT.title,
      path: paths.RETURNS_MANAGEMENT,
      icon: <SubdirectoryArrowLeft viewBox="2 0 30 30" />,
      children: RETURN_ROUTES_TO_DASHBOARD_VERSION_MAPPING[version],
      component: null,
    };
  } else {
    return {};
  }
};

const getGWPRoutes = (version, permissions, user) => {
  const moduleEnabled = user?.current_brand?.gift_with_purchase_module_enabled;
  const canViewGWP = permissions.includes(PERMISSIONS.VIEW_GWP_CAMPAIGNS);
  const canCreateGWP = permissions.includes(PERMISSIONS.CREATE_GWP_CAMPAIGNS);

  if (!moduleEnabled) return {};

  const ManageGwpListRoute = {
    path: paths.GWP_MANAGE_PROMOTIONS_LISTING,
    name: GWP.MANAGE_GWP.title,
    component: GiftWithPurchaseListing,
  };
  const CreateGwpRoute = {
    path: paths.GWP_CREATE_PROMOTION,
    name: GWP.CREATE_GWP.title,
    component: GiftWithPurchaseCreate,
  };

  const GWP_ROUTES_TO_DASHBOARD_VERSION_MAPPING = {
    2: [],
    3: [],
    4: [],
  };
  if (canViewGWP || canCreateGWP) {
    GWP_ROUTES_TO_DASHBOARD_VERSION_MAPPING[4].push(ManageGwpListRoute);
    GWP_ROUTES_TO_DASHBOARD_VERSION_MAPPING[2].push(ManageGwpListRoute);
  }
  if (canCreateGWP) {
    GWP_ROUTES_TO_DASHBOARD_VERSION_MAPPING[4].push(CreateGwpRoute);
    GWP_ROUTES_TO_DASHBOARD_VERSION_MAPPING[2].push(CreateGwpRoute);
  }

  if (canViewGWP || canCreateGWP) {
    return {
      id: GWP.title,
      name: GWP.title,
      path: paths.GIFT_WITH_PURCHASE,
      icon: <GiftIcon viewBox="2 0 30 30" />,
      children: GWP_ROUTES_TO_DASHBOARD_VERSION_MAPPING[version],
      component: null,
    };
  } else {
    return {};
  }
};

const homepageDashboardRoute = {
  id: HOME.title,
  path: paths.HOMEPAGE_DASHBOARD,
  name: HOME.title,
  icon: <Home />,
  component: HomepageDashboard,
};

const getOutboundRoutes = (version) => {
  const PulloutsRoute = {
    path: paths.PULLOUTS,
    name: PULLOUT_AND_B2B.PULLOUT_TRACKING.title,
    id: PULLOUT_AND_B2B.PULLOUT_TRACKING.title,
    component: Pullouts,
  };

  const CretaeDistributionOrderRoute = {
    path: paths.CREATE_DISTRIBUTION_ORDER,
    name: PULLOUT_AND_B2B.CREATE_DISTRIBUTION_ORDER.title,
    id: PULLOUT_AND_B2B.CREATE_DISTRIBUTION_ORDER.title,
    component: CreateDistributionOrder,
  };

  // const PulloutsUploadRoute = {
  //   path: paths.PULLOUTS_UPLOAD,
  //   name: PULLOUT_AND_B2B.PULLOUT_REQUESTS.title,
  //   id: PULLOUT_AND_B2B.PULLOUT_REQUESTS.title,
  //   component: PulloutsUpload,
  // };

  const OUTBOUND_ROUTES_TO_DASHBOARD_VERSION_MAPPING = {
    2: [CretaeDistributionOrderRoute, PulloutsRoute],
    3: [],
    4: [CretaeDistributionOrderRoute, PulloutsRoute],
  };

  return {
    id: PULLOUT_AND_B2B.title,
    name: PULLOUT_AND_B2B.title,
    path: paths.OUTBOUND,
    icon: <PulloutB2BSVG viewBox="0 0 32 32" />,
    children: OUTBOUND_ROUTES_TO_DASHBOARD_VERSION_MAPPING[version],
    component: null,
  };
};

const accountSettingsRoutes = {
  id: SETTINGS.ACCOUNT_SETTINGS.title,
  path: paths.ACCOUNT_SETTINGS,
  name: SETTINGS.ACCOUNT_SETTINGS.title,
  icon: <Settings />,
  component: AccountSettings,
};
const marketplaceSettingsRoutes = (permissions) => {
  return {
    id: SETTINGS.MARKETPLACE_INTEGRATIONS.title,
    path: paths.MARKETPLACE_SETTINGS,
    name: SETTINGS.MARKETPLACE_INTEGRATIONS.title,
    icon: <Share />,
    component: MarketplaceSettingsV2,
  };
};
const warehouseSettingsRoutes = {
  id: SETTINGS.WAREHOUSE_INTEGRATIONS.title,
  path: paths.WAREHOUSE_SETTINGS,
  name: SETTINGS.WAREHOUSE_INTEGRATIONS.title,
  icon: <Storage />,
  component: WarehouseSettings,
};
const carrierSettingsRoutes = {
  id: CARRIER.title,
  path: paths.CARRIER_SETTINGS,
  name: CARRIER.title,
  icon: <Storage />,
  component: CarrierSettings,
};
const carrierManagementRoutes = {
  id: SETTINGS.CARRIER_MANAGEMENT.title,
  path: paths.CARRIER_MANAGEMENT,
  name: SETTINGS.CARRIER_MANAGEMENT.title,
  component: CarrierManagement,
};

const upsellRoute = {
  id: <UpsellText />,
  name: LOCAD_STORAGE_AND_FULFILLMENT.title,
  path: paths.UPSELLING_ROUTE,
  icon: <StorageAndFulfillment viewBox="0 0 32 32" />,
  component: StorageAndFulfillmentUpselling,
};

const versionTwoSettingsRoutes = (version, permissions) => ({
  id: SETTINGS.title,
  name: SETTINGS.title,
  path: paths.SETTINGS,
  icon: <Settings />,
  children: [
    accountSettingsRoutes,
    marketplaceSettingsRoutes(permissions),
    warehouseSettingsRoutes,
    carrierSettingsRoutes,
    carrierManagementRoutes,
  ],
  component: null,
});

// eslint-disable-next-line no-unused-vars
const GenerateReportRoute = {
  id: 'New Report',
  path: paths.GENERATE_REPORTS,
  name: 'New Report',
  component: GenerateReport,
};

// eslint-disable-next-line no-unused-vars
const GeneratedReportRoute = {
  id: 'Generated Reports',
  path: paths.GENERATED_REPORTS,
  name: 'Generated Reports',
  component: GeneratedReport,
};
const ScheduledReportRoute = {
  id: 'Scheduled Reports',
  path: paths.SCHEDULED_REPORTS,
  name: 'Scheduled Reports',
  component: GeneratedScheduledReport,
};

const ReportRoutes = (permissions) => {
  const canScheduleReport = permissions.includes(PERMISSIONS.SCHEDULED_REPORT_VIEW_PERMISSION_MP);

  return {
    id: 'Report',
    name: 'Report',
    path: paths.REPORTS,
    icon: <ReportSVG />,
    children: [GenerateReportRoute, GeneratedReportRoute, canScheduleReport ? ScheduledReportRoute : null].filter(
      Boolean
    ),
    component: null,
  };
};

const getSupportRoutes = (version) => ({
  id: SUPPORT.title,
  path: paths.SUPPORT,
  name: SUPPORT.title,
  icon: <Help />,
  component: Support,
});

export const marketplaceRoutes = (permissions) => {
  return {
    market: {
      details: {
        id: SETTINGS.MARKETPLACE_INTEGRATIONS.DETAILS.title,
        path: paths.MARKETPLACE.market.details,
        component: MarketplaceDetailsV2,
        children: null,
      },
    },
    shopee: {
      authorize: {
        id: SETTINGS.MARKETPLACE_INTEGRATIONS.SHOPEE.title,
        path: paths.MARKETPLACE.Shopee.authorize,
        component: ShopeeAuthorize,
        children: null,
      },
    },
    lazada: {
      authorize: {
        id: SETTINGS.MARKETPLACE_INTEGRATIONS.LAZADA.AUTHO.title,
        path: paths.MARKETPLACE.Lazada.authorize,
        component: LazadaAuthorize,
        children: null,
      },
      validate: {
        id: SETTINGS.MARKETPLACE_INTEGRATIONS.LAZADA.VALI.title,
        path: paths.MARKETPLACE.Lazada.validate,
        component: LazadaAuthorize,
        children: null,
      },
    },
    tiktok: {
      authorize: {
        id: SETTINGS.MARKETPLACE_INTEGRATIONS.TIKTOK.AUTHO.title,
        path: paths.MARKETPLACE.Tiktok.authorize,
        component: TiktokAuthorize,
        children: null,
      },
      validate: {
        id: SETTINGS.MARKETPLACE_INTEGRATIONS.TIKTOK.VALI.title,
        path: paths.MARKETPLACE.Tiktok.validate,
        component: TiktokAuthorize,
        children: null,
      },
    },
    wix: {
      authorize: {
        id: SETTINGS.MARKETPLACE_INTEGRATIONS.WIX.AUTHO.title,
        path: paths.MARKETPLACE.Wix.authorize,
        component: WixAuthorize,
        children: null,
      },
      validate: {
        id: SETTINGS.MARKETPLACE_INTEGRATIONS.WIX.VALI.title,
        path: paths.MARKETPLACE.Wix.validate,
        component: WixAuthorize,
        children: null,
      },
    },
    tokopedia: {
      authorize: {
        id: SETTINGS.MARKETPLACE_INTEGRATIONS.TOKOPEDIA.AUTHO.title,
        path: paths.MARKETPLACE.Tokopedia.authorize,
        component: TokopediaAuthorize,
        children: null,
      },
      validate: {
        id: SETTINGS.MARKETPLACE_INTEGRATIONS.TOKOPEDIA.VALI.title,
        path: paths.MARKETPLACE.Tokopedia.validate,
        component: TokopediaAuthorize,
        children: null,
      },
    },
    zalora: {
      authorize: {
        id: SETTINGS.MARKETPLACE_INTEGRATIONS.ZALORA.title,
        path: paths.MARKETPLACE.Zalora.authorize,
        component: ZaloraAuthorize,
        children: null,
      },
    },
    custom: {
      authorize: {
        id: SETTINGS.MARKETPLACE_INTEGRATIONS.CUSTOM.AUTHO.title,
        path: paths.MARKETPLACE.Custom.authorize,
        component: CustomWebstoreAuthorize,
        children: null,
      },
    },
    capillary: {
      authorize: {
        id: SETTINGS.MARKETPLACE_INTEGRATIONS.CAPILLARY.AUTHO.title,
        path: paths.MARKETPLACE.Capillary.authorize,
        component: CapillaryAuthorize,
        children: null,
      },
    },
    kumu: {
      authorize: {
        id: SETTINGS.MARKETPLACE_INTEGRATIONS.KUMU.AUTHO.title,
        path: paths.MARKETPLACE.Kumu.authorize,
        component: KumuAuthorize,
        children: null,
      },
    },
    bbo: {
      authorize: {
        id: SETTINGS.MARKETPLACE_INTEGRATIONS.BBO.AUTHO.title,
        path: paths.MARKETPLACE.Bbo.authorize,
        component: BboAuthorize,
        children: null,
      },
    },
    ginee: {
      authorize: {
        id: SETTINGS.MARKETPLACE_INTEGRATIONS.GINEE.AUTHO.title,
        path: paths.MARKETPLACE.Ginee.authorize,
        component: GineeAuthorize,
        children: null,
      },
    },
    shopify: {
      authorize: {
        id: SETTINGS.MARKETPLACE_INTEGRATIONS.SHOPIFY.AUTHO.title,
        path: paths.MARKETPLACE.Shopify.authorize,
        component: ShopifyAuthorize,
        children: null,
      },
    },
    woocommerce: {
      authorize: {
        id: SETTINGS.MARKETPLACE_INTEGRATIONS.WOOCOMMERCE.AUTHO.title,
        path: paths.MARKETPLACE.Woocommerce.authorize,
        component: WoocommerceAuthorize,
        children: null,
      },
    },
    amazon: {
      authorize: {
        id: SETTINGS.MARKETPLACE_INTEGRATIONS.AMAZON.AUTHO.title,
        path: paths.MARKETPLACE.Amazon.authorize,
        component: AmazonAuthorize,
        children: null,
      },
    },
    ebay: {
      authorize: {
        id: SETTINGS.MARKETPLACE_INTEGRATIONS.EBAY.AUTHO.title,
        path: paths.MARKETPLACE.Ebay.authorize,
        component: EbayAuthorize,
        children: null,
      },
      validate: {
        id: SETTINGS.MARKETPLACE_INTEGRATIONS.EBAY.VALI.title,
        path: paths.MARKETPLACE.Ebay.authorize,
        component: EbayAuthorize,
        children: null,
      },
    },
  };
};

export const warehouseRoutes = {
  warehouse: {
    details: {
      id: SETTINGS.WAREHOUSE_INTEGRATIONS.WAREHOUSE_DETAILS.title,
      path: paths.WAREHOUSE.warehouse.details,
      component: WarehouseDetails,
    },
  },
  wareo: {
    authorize: {
      id: SETTINGS.WAREHOUSE_INTEGRATIONS.WAREO.title,
      path: paths.WAREHOUSE.wareo.authorize,
      component: WareoAuthorize,
    },
  },
  uniware: {
    authorize: {
      id: SETTINGS.WAREHOUSE_INTEGRATIONS.UNIWARE.title,
      path: paths.WAREHOUSE.uniware.authorize,
      component: UniwareAuthorize,
    },
  },
  logiwa: {
    authorize: {
      id: SETTINGS.WAREHOUSE_INTEGRATIONS.LOGIWA.title,
      path: paths.WAREHOUSE.logiwa.authorize,
      component: LogiwaAuthorize,
    },
  },
  wareo3: {
    authorize: {
      id: SETTINGS.WAREHOUSE_INTEGRATIONS.WAREO_3.title,
      path: paths.WAREHOUSE.wareo3.authorize,
      component: Wareo3Authorize,
    },
  },
};

export const carrierRoutes = {
  carrier: {
    details: {
      id: CARRIER.DETAIL.title,
      path: paths.CARRIER.carrier.details,
      component: CarrierDetails,
    },
  },
  ninjavan: {
    authorize: {
      id: CARRIER.NINJAVAN.title,
      path: paths.CARRIER.ninjavan.authorize,
      component: NinjavanAuthorize,
    },
  },
  entrego: {
    authorize: {
      id: CARRIER.ENTREGO.title,
      path: paths.CARRIER.entrego.authorize,
      component: EntregoAuthorize,
    },
  },
  xde: {
    authorize: {
      id: CARRIER.XDE.title,
      path: paths.CARRIER.xde.authorize,
      component: XDEAuthorize,
    },
  },
  lalamove: {
    authorize: {
      id: CARRIER.LALAMOVE.title,
      path: paths.CARRIER.lalamove.authorize,
      component: LalamoveAuthorize,
    },
  },
  pickupp: {
    authorize: {
      id: CARRIER.PICKUPP.title,
      path: paths.CARRIER.pickupp.authorize,
      component: PickuppAuthorize,
    },
  },
  jntph: {
    authorize: {
      id: CARRIER.JNTPH.title,
      path: paths.CARRIER.jntph.authorize,
      component: JntPhAuthorize,
    },
  },
  jntsg: {
    authorize: {
      id: CARRIER.JNTSG.title,
      path: paths.CARRIER.jntsg.authorize,
      component: JntSgAuthorize,
    },
  },
  lbc: {
    authorize: {
      id: CARRIER.LBC.title,
      path: paths.CARRIER.lbc.authorize,
      component: LbcAuthorize,
    },
  },
  dhl: {
    authorize: {
      id: CARRIER.DHL.title,
      path: paths.CARRIER.dhl.authorize,
      component: DhlAuthorize,
    },
  },
  auspost: {
    authorize: {
      id: CARRIER.AUSPOST.title,
      path: paths.CARRIER.auspost.authorize,
      component: AusPostAuthorize,
    },
  },
  asendia: {
    authorize: {
      id: CARRIER.ASCENDIA.title,
      path: paths.CARRIER.asendia.authorize,
      component: AsendiaAuthorize,
    },
  },
  sendle: {
    authorize: {
      id: CARRIER.SENDLE.title,
      path: paths.CARRIER.sendle.authorize,
      component: SendleAuthorize,
    },
  },
  kerry: {
    authorize: {
      id: CARRIER.KERRY.title,
      path: paths.CARRIER.kerry.authorize,
      component: KerryAuthorize,
    },
  },
  teleport: {
    authorize: {
      id: CARRIER.TELEPORT.title,
      path: paths.CARRIER.teleport.authorize,
      component: TeleportAuthorize,
    },
  },
  singpost: {
    authorize: {
      id: CARRIER.SINGPOST.title,
      path: paths.CARRIER.singpost.authorize,
      component: SingpostAuthorize,
    },
  },
};
export const PaymentRoute = {
  id: OTHERS.PAYMENT.title,
  path: paths.LOCAD_PAYMENT,
  name: OTHERS.PAYMENT.title,
  component: LocadPayment,
};

export const PaymentCallBack = {
  id: OTHERS.PAYMENT_CALLBACK.title,
  path: paths.LOCAD_PAYMENT_CALLBACK,
  name: OTHERS.PAYMENT_CALLBACK.title,
  component: LocadPaymentCallback,
  skeletonLayout: true,
};

// export const MigrationUserConfirmationRoute = {
//   id: 'MigrationUserConfirmation',
//   path: paths.AUTH_MIGRATE_USER_CONFIRMATION,
//   name: 'MigrationUserConfirmation',
//   component: MigrationUserConfirmation,
//   skeletonLayout: true,
// };
const storageRateEditRoute = {
  id: BILLING.BILLING_RATES.STORAGE_RATE_EDIT.title,
  path: paths.RATES_BILLING_EDIT_STORAGE,
  component: EditStorageRateView,
};
const fulfillmentRateEditRoute = {
  id: BILLING.SHIPMENT_BILLING.title,
  path: paths.RATES_BILLING_EDIT_FULFILLMENT,
  component: EditFulfillmentRateView,
};
const returnRateEditRoute = {
  id: BILLING.BILLING_RATES.STORAGE_RATE_EDIT.title,
  path: paths.RATES_BILLING_EDIT_RETURN,
  component: EditReturnRateView,
};
const pulloutRateEditRoute = {
  id: BILLING.BILLING_RATES.STORAGE_RATE_EDIT.title,
  path: paths.RATES_BILLING_EDIT_PULLOUT,
  component: EditPulloutRateView,
};

const FulfillmentDashboardV2Route = {
  id: FULFILLMENT.F_DASHBOARD_V2.title,
  path: paths.FULFILLMENT_DASHBOAARD_V2,
  component: ZeusFulfillmentDashboardV2,
};

const returnOrderDetailRoute = {
  id: RETURNS_MANAGEMENT.RETURN_ORDER_DETAIL.title,
  path: paths.RETURN_ORDER_DETAIL,
  component: ReturnOrderDetail,
};

//These routes are not visible in Sidebar
const PRIVATE_TO_DASHBOARD_VERSION_MAPPING = {
  1: [
    {
      id: HOME.title,
      path: paths.HOMEPAGE_DASHBOARD,
      component: HomepageDashboard,
    },
    {
      id: ORDER.ORDER_FORECAST.title,
      path: paths.FULFILLMENT_ORDER_FORECAST,
      name: ORDER.ORDER_FORECAST.title,
      component: OrderForcasting,
    },
    { ...PaymentRoute },
    { ...PaymentCallBack },

    { ...FulfillmentDashboardV2Route },
  ],
  2: [
    {
      id: HOME.title,
      path: paths.HOMEPAGE_DASHBOARD,
      component: HomepageDashboard,
    },
    {
      id: PRODUCTS.INVENTORY_PRODUCTS.CREATE_INVENTORY_PROUDUCTS.title,
      path: paths.INVENTORY_PRODUCT_CREATE,
      component: CreateInventoryProduct,
      children: null,
    },
    {
      id: GWP.MANAGE_GWP_DETAIL.title,
      path: paths.GWP_MANAGE_PROMOTIONS_DETAIL,
      component: GiftWithPurchaseDetail,
      children: null,
    },
    {
      id: INBOUND.ASN_DETAIL.title,
      path: paths.ADVANCE_SHIPPING_NOTES_LISTING,
      component: AdvanceShippingNotesDetail,
      children: null,
    },
    {
      id: PULLOUT_AND_B2B.PULLOUT_DETAIL.title,
      path: paths.PULLOUTS_LISTING,
      component: PulloutsDetail,
      children: null,
    },
    {
      id: PULLOUT_AND_B2B.PULLOUT_REVIEW_DETAIL.title,
      path: paths.B2B_ORDER_DETAILS,
      component: B2BReview,
    },
    storageRateEditRoute,
    fulfillmentRateEditRoute,
    returnRateEditRoute,
    pulloutRateEditRoute,
    // {
    //   id: SETTINGS.CARRIER_MANAGEMENT.title,
    //   path: paths.CARRIER_MANAGEMENT,
    //   component: CarrierManagement,
    //   children: null,
    // },
    {
      id: ORDER.ORDER_FORECAST.title,
      path: paths.FULFILLMENT_ORDER_FORECAST,
      name: ORDER.ORDER_FORECAST.title,
      component: OrderForcasting,
    },
    { ...PaymentRoute },
    { ...PaymentCallBack },
    // {...MigrationUserConfirmationRoute},
    { ...FulfillmentDashboardV2Route },
  ],
  3: [
    {
      id: HOME.title,
      path: paths.HOMEPAGE_DASHBOARD,
      component: HomepageDashboard,
    },
    {
      id: FULFILLMENT.CREATE_SHIPMENT_ORDER.title,
      path: paths.FULFILLMENT_SHIPPING_CREATE_ORDER,
      component: CreateShipmentOrder,
      children: null,
    },
    {
      id: FULFILLMENT.CREATE_PICKUP_LOCATION.title,
      path: paths.FULFILLMENT_CREATE_PICKUP_LOCATION,
      component: CreatePickupLocation,
      children: null,
    },
    {
      id: PULLOUT_AND_B2B.PULLOUT_REVIEW_DETAIL.title,
      path: paths.B2B_ORDER_DETAILS,
      component: B2BReview,
    },
    storageRateEditRoute,
    fulfillmentRateEditRoute,
    returnRateEditRoute,
    pulloutRateEditRoute,
    // {
    //   id: SETTINGS.CARRIER_MANAGEMENT.title,
    //   path: paths.CARRIER_MANAGEMENT,
    //   component: CarrierManagement,
    //   children: null,
    // },
    {
      id: ORDER.ORDER_FORECAST.title,
      path: paths.FULFILLMENT_ORDER_FORECAST,
      name: ORDER.ORDER_FORECAST.title,
      component: OrderForcasting,
    },
    { ...PaymentRoute },
    { ...PaymentCallBack },
    // {...MigrationUserConfirmationRoute},
    { ...FulfillmentDashboardV2Route },
  ],
  4: [
    {
      id: HOME.title,
      path: paths.HOMEPAGE_DASHBOARD,
      component: HomepageDashboard,
    },
    {
      id: PRODUCTS.INVENTORY_PRODUCTS.CREATE_INVENTORY_PROUDUCTS.title,
      path: paths.INVENTORY_PRODUCT_CREATE,
      component: CreateInventoryProduct,
      children: null,
    },
    {
      id: GWP.MANAGE_GWP_DETAIL.title,
      path: paths.GWP_MANAGE_PROMOTIONS_DETAIL,
      component: GiftWithPurchaseDetail,
      children: null,
    },
    {
      id: INBOUND.ASN_DETAIL.title,
      path: paths.ADVANCE_SHIPPING_NOTES_LISTING,
      component: AdvanceShippingNotesDetail,
      children: null,
    },
    {
      id: PULLOUT_AND_B2B.PULLOUT_DETAIL.title,
      path: paths.PULLOUTS_LISTING,
      component: PulloutsDetail,
      children: null,
    },
    {
      id: FULFILLMENT.CREATE_SHIPMENT_ORDER.title,
      path: paths.SHIPMENTS_CREATE_ORDER,
      component: CreateShipmentOrder,
      children: null,
    },
    {
      id: FULFILLMENT.CREATE_PICKUP_LOCATION.title,
      path: paths.CREATE_PICKUP_LOCATION,
      component: CreatePickupLocation,
      children: null,
    },
    storageRateEditRoute,
    fulfillmentRateEditRoute,
    returnRateEditRoute,
    pulloutRateEditRoute,
    returnOrderDetailRoute,
    // {
    //   id: SETTINGS.CARRIER_MANAGEMENT.title,
    //   path: paths.CARRIER_MANAGEMENT,
    //   component: CarrierManagement,
    //   children: null,
    // },
    {
      id: ORDER.ORDER_FORECAST.title,
      path: paths.FULFILLMENT_ORDER_FORECAST,
      name: ORDER.ORDER_FORECAST.title,
      component: OrderForcasting,
    },
    {
      id: PULLOUT_AND_B2B.PULLOUT_REVIEW_DETAIL.title,
      path: paths.B2B_ORDER_DETAILS,
      component: B2BReview,
    },
    { ...PaymentRoute },
    { ...PaymentCallBack },
    // {...MigrationUserConfirmationRoute},
    { ...FulfillmentDashboardV2Route },
  ],
};
const getPrivateRoutes = (version) => PRIVATE_TO_DASHBOARD_VERSION_MAPPING[version];

//These routes are not visible in Sidebar and use the Auth Layout
const authRoutes = {
  id: AUTH.title,
  path: paths.AUTH,
  children: [
    {
      path: paths.AUTH_LOGIN + '/:skin?',
      name: AUTH.LOGIN.title,
      component: Login,
    },
    {
      path: paths.AUTH_LINK_BRAND,
      name: AUTH.LINK.title,
      component: ConfirmScreen,
    },
    {
      path: paths.AUTH_EXPIRED_EMAIL_INVITE,
      name: AUTH.EMAIL_EXPIRED.title,
      component: ExpiredInviteWarning,
    },
    // We are deprecating the migration flow
    // {
    //   path: paths.AUTH_MIGRATE_USER,
    //   name: 'MigrateUser',
    //   component: MigrateUserComponent,
    // },
    {
      path: paths.AUTH_CALLBACK,
      name: 'Cognito Callback',
      component: AuthCallback,
    },
    {
      path: paths.FRESHDESK_LOGIN,
      name: 'Freshdesk Login',
      component: FreshdeskLogin,
    },
    // {
    //   path: paths.AUTH_MIGRATE_USER_REDIRECT,
    //   name: 'User Migration Callback',
    //   component: MigrationUserCallBack,
    // },
    {
      path: paths.AUTH_EMAIL_INVITE_REDIRECT,
      name: 'Email Invite Callback',
      component: EmailInvitationCallBack,
    },
    {
      path: paths.AUTH_EMAIL_INVITATION,
      name: AUTH.EMAIL_INVITATION.title,
      component: SignUPEmailInvite,
    },
    {
      path: paths.AUTH_EMAIL_MISMATCH_WARNING,
      name: AUTH.EMAIL_MISMATCH.title,
      component: Warning,
    },
    {
      path: paths.AUTH_SIGNUP,
      name: AUTH.SIGNUP.title,
      component: SignUp,
    },
    {
      path: paths.AUTH_SHOPIFY_SIGNUP,
      name: AUTH.SHOPIFY_SIGNUP.title,
      component: ShopifyIntegration,
    },
    {
      path: paths.AUTH_AMAZON_SIGNUP,
      name: AUTH.AMAZON_SIGNUP.title,
      component: AmazonIntegration,
    },
    {
      path: paths.AUTH_SHOPIFY_CREATE_USER,
      name: AUTH.SHOPIFY_CREATE_USER.title,
      component: ShopifyCreateUser,
    },
    {
      path: paths.AUTH_SHOPIFY_MAP_USER_LOCATION,
      name: AUTH.SHOPIFY_MAP_USER_LOCATION.title,
      component: ShopifyUserMapLocation,
    },
    {
      path: paths.AUTH_SHOPIFY_HUBSPOT_USER_SIGN_IN,
      name: AUTH.SHOPIFY_HUBSPOT_USER_SIGN_IN.title,
      component: ShopifyHubspotUserSignin,
    },
    //  We are deprecating the migration flow
    // {
    //   path: paths.AUTH_SIGNUP_CREATE_BRAND,
    //   name: "PublicCreateBrand",
    //   component: SignUpBrandCreate,
    // },
    // {
    //   path: paths.AUTH_SIGNUP_BRAND_MAPPING,
    //   name: "PublicSignUpBrandMapping",
    //   component: SignUpBrandMapping,
    // },
    {
      path: paths.AUTH_404,
      name: AUTH.AUTH_404.title,
      component: Page404,
    },
    {
      path: paths.AUTH_500,
      name: AUTH.AUTH_500.title,
      component: Page500,
    },
    {
      path: paths.AUTH_LOGOUT,
      name: AUTH.AUTH_LOGOUT.title,
      component: LogoutScreen,
    },
  ],
  component: null,
};

//footer links routes

const privacyRoute = {
  id: OTHERS.PRIVACY.title,
  path: paths.PRIVACY,
  component: Privacy,
};

const termsOfServiceRoute = {
  id: OTHERS.TERMS_OF_SERVICE.title,
  path: paths.TERMS_OF_SERVICE,
  component: TermsOfService,
};

const SIDEBAR_ROUTES_TO_DASHBOARD_VERSION_MAPPING = (version, permissions, user) => {
  const isEtailyBrand = user?.current_brand?.is_etaily;

  const routes = {
    1: [
      homepageDashboardRoute,
      getSalesRoutes(1),
      getFulfillmentRoutes(1, permissions),
      getInventoryRoutes(1),
      ReportRoutes(permissions),
      accountSettingsRoutes,
      getSupportRoutes(1),
    ],
    2: [
      homepageDashboardRoute,
      getSalesRoutes(2),
      getFulfillmentRoutes(2, permissions),
      getReturnRoutes(2, permissions),
      getInventoryRoutes(2),
      getGWPRoutes(2, permissions, user),
      ReportRoutes(permissions),
      getInboundRoutes(2),
      getOutboundRoutes(2),
      getBillingRoutes(2, permissions, user),
      versionTwoSettingsRoutes(2, permissions),
      getSupportRoutes(2),
    ],
    3: [
      homepageDashboardRoute,
      getSalesRoutes(3),
      getFulfillmentRoutes(3, permissions),
      getBillingRoutes(3, permissions, user),
      ReportRoutes(permissions),
      accountSettingsRoutes,
      getSupportRoutes(3),
    ],
    4: [
      homepageDashboardRoute,
      !isEtailyBrand && upsellRoute,
      getSalesRoutes(4),
      getFulfillmentRoutes(4, permissions),
      returnKeyDashboardRoute,
      getReturnRoutes(4, permissions),
      getShipmentRoutes(),
      getPickupLocationsRoutes(),
      getInventoryRoutes(4),
      getGWPRoutes(4, permissions, user),
      ReportRoutes(permissions),
      getInboundRoutes(4),
      getOutboundRoutes(4),
      getBillingRoutes(4, permissions, user),
      versionTwoSettingsRoutes(4, permissions),
      getWalletRoutes(4),
      getSupportRoutes(4),
    ],
  };
  return routes[version].filter((x) => x?.id);
};

const filterRoutes = (routes, isStaff) => {
  if (isStaff) return routes;

  const settingsRoute = routes.find((route) => route.id === SETTINGS.title);

  if (settingsRoute) {
    const filtered = settingsRoute.children.filter((child) => child.id !== CARRIER.title);
    settingsRoute.children = filtered;
  }

  // Only is_staff user has access for Request, so remove
  // if (isStaff === false) {
  //   const pullOutB2b = routes.find((r) => r.id === PULLOUT_AND_B2B.title);
  //   if (pullOutB2b) {
  //     const childRoutes = pullOutB2b.children;
  //     const index = childRoutes.findIndex((r) => r.id === PULLOUT_AND_B2B.PULLOUT_REQUESTS.title);
  //     if (index >= 0) {
  //       childRoutes.splice(index, 1); // removing routes for non-staff user
  //     }
  //   }
  // }
  return routes;
};

export const getSidebarRoutes = (version, user) => {
  const permissions = user?.permissions || [];
  const defaultRoutes = SIDEBAR_ROUTES_TO_DASHBOARD_VERSION_MAPPING(version, permissions, user);
  const userDashboardFlags = getUserDashboardFlags(user);
  return version === 4 ? applyDashboardFlags(defaultRoutes, userDashboardFlags) : defaultRoutes;
};

export const getDashboardLayoutRoutes = (version, user) => {
  const permissions = user?.permissions || [];
  let dashboardLayoutRoutes = filterRoutes(
    [...getSidebarRoutes(version, user), ...getPrivateRoutes(version), privacyRoute, termsOfServiceRoute],
    user?.is_staff
  );

  const DASHBOARD_LAYOUT_ROUTES_TO_DASHBOARD_VERSION_MAPPING = {
    1: dashboardLayoutRoutes,
    2: [
      ...dashboardLayoutRoutes,
      ...Object.values(marketplaceRoutes(permissions)).flatMap((x) => Object.values(x)),
      ...Object.values(warehouseRoutes).flatMap((x) => Object.values(x)),
      ...Object.values(carrierRoutes).flatMap((x) => Object.values(x)),
    ],
    3: dashboardLayoutRoutes,
    4: [
      ...dashboardLayoutRoutes,
      ...Object.values(marketplaceRoutes(permissions)).flatMap((x) => Object.values(x)),
      ...Object.values(warehouseRoutes).flatMap((x) => Object.values(x)),
      ...Object.values(carrierRoutes).flatMap((x) => Object.values(x)),
    ],
  };

  return DASHBOARD_LAYOUT_ROUTES_TO_DASHBOARD_VERSION_MAPPING[version];
};

export const getAuthLayoutRoutes = () => {
  return [authRoutes];
};

// export const getIndexRoute = (version) =>
//   DASHBOARD_INDEX_TO_VERSION_MAPPING[version];
export const getIndexRoute = (version) => paths.HOMEPAGE_DASHBOARD;
