export const GET_MARKETPLACES = 'GET_MARKETPLACES';
export const GET_MARKETPLACES_SUCCESS = 'GET_MARKETPLACES_SUCCESS';
export const GET_MARKETPLACES_FAIL = 'GET_MARKETPLACES_FAIL';
export const GET_CONFIGURED_MARKETPLACES = 'GET_CONFIGURED_MARKETPLACES';
export const GET_CONFIGURED_MARKETPLACES_SUCCESS = 'GET_CONFIGURED_MARKETPLACES_SUCCESS';
export const GET_CONFIGURED_MARKETPLACES_FAIL = 'GET_CONFIGURED_MARKETPLACES_FAIL';
export const GET_AVAILABLE_MARKETPLACES = 'GET_AVAILABLE_MARKETPLACES';
export const GET_AVAILABLE_MARKETPLACES_SUCCESS = 'GET_AVAILABLE_MARKETPLACES_SUCCESS';
export const GET_AVAILABLE_MARKETPLACES_FAIL = 'GET_AVAILABLE_MARKETPLACES_FAIL';
export const MARKETPLACE_DETAILS_SET = 'MARKETPLACE_DETAILS_SET';
export const MARKETPLACES_DETAILS_SET_SUCCESS = 'MARKETPLACES_DETAILS_SET_SUCCESS';
export const MARKETPLACES_DETAILS_SET_FAIL = 'MARKETPLACES_DETAILS_SET_FAIL';
