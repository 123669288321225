import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';

import {
  FormControl as MuiFormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Autocomplete,
} from '@mui/material';
import { sizing } from '@mui/system';

const FormControl = styled(MuiFormControl)(sizing);

const SelectLoader = () => <CircularProgress size={15} />;

const emptyOption = [{ value: '', label: ' ' }];

function AppSelect({
  name,
  error,
  label,
  options,
  isLoading,
  disabled,
  placeholder = '',
  searchable,
  onOpen,
  required,
  helperText,
  autoFocus = true,
  withEmptyOption = false,
  variant = 'standard',
  onClose,
  isOpen = true,
  width = '100%',
  innerInputProps = {},
  CustomTextField = null,
  customAutocompleteProps,
  ...rest
}) {
  const [autocompleteValue, setAutocompleteValue] = React.useState('');
  const initialValueIsSet = React.useRef(false);
  const inputRef = React.useRef(null);

  const additionalProps = isLoading ? { IconComponent: SelectLoader } : {};
  const autoselectInputAdornment = isLoading ? (
    <InputAdornment position="start">
      <SelectLoader />
    </InputAdornment>
  ) : null;

  return searchable ? (
    <>
      <Autocomplete
        loading={isLoading}
        disabled={disabled}
        openOnFocus
        onOpen={onOpen}
        onClose={onClose}
        value={isLoading ? null : rest.value}
        onChange={(event, newValue) => {
          rest.onChange({ target: { value: newValue ? newValue.value : null, name: event?.target?.name ?? '' } });
        }}
        inputValue={autocompleteValue}
        onInputChange={(event, newInputValue) => {
          // clear input value on first render if autoFocus is true.
          const newValue = autoFocus && !initialValueIsSet.current ? '' : newInputValue;
          initialValueIsSet.current = true;
          setAutocompleteValue(newValue);
        }}
        options={withEmptyOption ? emptyOption.concat(options) : options}
        renderOption={(props, option) => <li {...props}>{option.label}</li>}
        isOptionEqualToValue={(option, value) => option.value === value}
        getOptionLabel={(o) => {
          return typeof o === 'object' ? o.label || '' : options.find((op) => op.value === o)?.label || '';
        }}
        style={{ width: width }}
        renderInput={({ InputProps, ...params }) => {
          const TextFieldComponent = CustomTextField ? CustomTextField : TextField;
          return (
            <TextFieldComponent
              id="autocomplete-input"
              error={error}
              label={label}
              autoFocus={autoFocus}
              name={name}
              ref={inputRef}
              placeholder={placeholder}
              InputProps={{
                ...InputProps,
                startAdornment: autoselectInputAdornment,
                ...innerInputProps,
              }}
              onKeyPress={(e) => {
                if (!isOpen && e.key === 'Enter' && rest.onSave) rest.onSave();
              }}
              variant="standard"
              {...params}
            />
          );
        }}
        {...customAutocompleteProps}
      />
      {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
    </>
  ) : (
    <FormControl width={1} error={error} required={required} variant={variant}>
      {label ? <InputLabel htmlFor={name}>{label}</InputLabel> : null}
      <Select
        variant="standard"
        fullWidth
        label={label}
        name={name}
        inputProps={{ name: name }}
        disabled={isLoading || disabled}
        {...additionalProps}
        {...rest}
      >
        {options?.map((option) => {
          return (
            <MenuItem disabled={option.disabled} key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          );
        })}
      </Select>
      {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
    </FormControl>
  );
}

AppSelect.propTypes = {
  name: PropTypes.string,
  isLoading: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    })
  ),
  // and other mui select props
};

export default AppSelect;
