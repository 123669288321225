import React, { useState, useEffect } from 'react';
import store from './redux/store/index';
import { connect } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import PageTitle from './Components/PageTitle';
import WithReduxStore from './Components/WithReduxStore';

import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { StylesProvider } from '@mui/styles';
import { ThemeProvider } from 'styled-components';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import maTheme from './theme';
import Routes from './routes/Routes';
import ReactGA from 'react-ga4';

import BannerComponent from './Components/BannerComponent';
import DomainMovedbanner from './pages/BannerComponents/DomainMoveBanner';
import { ScopeContext } from './providers/ScopeProvider';
import { datadogRum } from '@datadog/browser-rum';
import MaintenanceBanner from './pages/BannerComponents/MaintenanceBanner';
import { Box } from '@mui/material';

// if (process.env.NODE_ENV === "development") {
//   const {worker} = require('./msw/browser/server')
//   worker.start()
// }

if (window.locadui.ENABLE_DATADOG === 'yes') {
  datadogRum.init({
    applicationId: 'e9046d69-bf16-4125-814f-9632d12a7f23',
    clientToken: 'pub34871d304b653d57fb3de2ff130c2691',
    site: 'datadoghq.com',
    service: 'locad-webapp',
    env: process.env.REACT_APP_ENV || 'local',
    //  version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 25,
    trackInteractions: true,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
}

const Render = ({ theme }) => {
  const ga_measurement_id = window?.locadui?.GA_ID;

  ReactGA.initialize([
    {
      trackingId: ga_measurement_id,
      gtagOptions: { debug_mode: window?.locadui?.GA_DEBUG },
    },
  ]);

  /**
   * This commented out section below is used to show the banner for any new changes
   * It uses localstorage to validate the banner choice made by user! whether to show
   * it or not
   */
  const [announcement, setAnnouncement] = useState(false);
  const [showBanner, setshowBanner] = useState(false);
  const [showMaintenanceBanner, setShowMaintenanceBanner] = useState(false);

  const initializeFresworks = () => {
    let s = document.createElement('script');
    s.type = 'text/javascript';
    s.src = 'https://widget.freshworks.com/widgets/70000003629.js';
    s.defer = true;
    s.async = true;
    document.head.append(s);
  };

  useEffect(() => {
    initializeFresworks();
  }, []);

  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (window?.locadui?.ENABLE_MAINTENANCE && String(window?.locadui?.ENABLE_MAINTENANCE) == 'true')
      setShowMaintenanceBanner(true);
  }, []);

  return (
    <>
      <PageTitle />
      <StylesProvider injectFirst>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StyledEngineProvider injectFirst>
            <MuiThemeProvider theme={maTheme[theme.currentTheme]}>
              <ThemeProvider theme={maTheme[theme.currentTheme]}>
                {!announcement ? (
                  <BannerComponent
                    onBannerClose={setshowBanner}
                    isOpen={showBanner}
                    vertical={'top'}
                    horizontal={'center'}
                    severity="info"
                  >
                    <DomainMovedbanner closebanner={setshowBanner} removeDomainMessage={setAnnouncement} />
                  </BannerComponent>
                ) : null}
                {showMaintenanceBanner ? (
                  <BannerComponent
                    onBannerClose={() => {}}
                    isOpen={showMaintenanceBanner}
                    vertical={'top'}
                    horizontal={'center'}
                    severity="info"
                  >
                    <MaintenanceBanner />
                  </BannerComponent>
                ) : null}
                <ScopeContext.Provider value={'MERCHANT_PORTAL'}>
                  <Box style={showMaintenanceBanner ? { opacity: 0.7 } : {}}>
                    <Router>
                      <Routes />
                    </Router>
                  </Box>
                </ScopeContext.Provider>
              </ThemeProvider>
            </MuiThemeProvider>
          </StyledEngineProvider>
        </LocalizationProvider>
      </StylesProvider>
    </>
  );
};

const ConnectedRender = connect(({ theme }) => ({ theme }))(Render);

const App = () => {
  return (
    <WithReduxStore store={store}>
      <ConnectedRender />
    </WithReduxStore>
  );
};

export default App;
