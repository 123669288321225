import {
  GET_MARKETPLACES,
  GET_MARKETPLACES_SUCCESS,
  GET_MARKETPLACES_FAIL,
  GET_CONFIGURED_MARKETPLACES,
  GET_AVAILABLE_MARKETPLACES,
  GET_AVAILABLE_MARKETPLACES_FAIL,
  GET_CONFIGURED_MARKETPLACES_FAIL,
  GET_CONFIGURED_MARKETPLACES_SUCCESS,
  GET_AVAILABLE_MARKETPLACES_SUCCESS,
  MARKETPLACE_DETAILS_SET,
  MARKETPLACES_DETAILS_SET_SUCCESS,
  MARKETPLACES_DETAILS_SET_FAIL,
} from './actionTypes';

const initialState = {
  marketplaces: [],
  configuredMarketplaces: [],
  availableMarketplaces: [],
  marketplaceDetails: {},
  brand: null,
  carrierConnections: [],
  isLoading: false,
  error: null,
  currentMarketplace: {},
  isDetailLoading: false,
  detailError: null,
};

export default function marketplaces(state = initialState, action) {
  switch (action.type) {
    case GET_MARKETPLACES:
    case GET_CONFIGURED_MARKETPLACES:
    case GET_AVAILABLE_MARKETPLACES: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case GET_MARKETPLACES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        marketplaces: action.response.marketplaces,
        carrierConnections: action.response.available_carrier_connections,
        brand: action.response.brand,
      };
    }
    case GET_CONFIGURED_MARKETPLACES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        configuredMarketplaces: action.response,
      };
    }
    case GET_AVAILABLE_MARKETPLACES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        availableMarketplaces: action.response,
      };
    }
    case GET_MARKETPLACES_FAIL:
    case GET_AVAILABLE_MARKETPLACES_FAIL:
    case GET_CONFIGURED_MARKETPLACES_FAIL: {
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    }
    case MARKETPLACE_DETAILS_SET: {
      return {
        ...state,
        isDetailLoading: true,
        detailError: null,
      };
    }
    case MARKETPLACES_DETAILS_SET_SUCCESS: {
      return {
        ...state,
        isDetailLoading: false,
        detailError: null,
        currentMarketplace: action.response,
      };
    }
    case MARKETPLACES_DETAILS_SET_FAIL: {
      return {
        ...state,
        isDetailLoading: false,
        detailError: true,
      };
    }
    default:
      return state;
  }
}
