export const GWP = {
  title: 'Gift With Purchase',
  CREATE_GWP: {
    title: 'Create Promotion',
  },
  MANAGE_GWP: {
    title: 'Manage Promotions',
  },
  MANAGE_GWP_DETAIL: {
    title: 'Promotion Detail',
  },
};
